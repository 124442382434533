// 
// user.scss
// Use this to write your custom SCSS
//

body {
  background: #f5f5f5 url(../img/page-title/blog-pattern.jpg);

  .page-title {
    min-height: 150px;
    background-color: transparent;
    margin-bottom: 0;

    h1 {
      margin-bottom: 0;
    }
  }

  .container {
    .box-shadow {
      background: #fff;
    }
  }

  .step .step-number {
    color: #222;
  }

  .progress-bar i, .progress-label i {
    font-size: 1.4em;
  }

  .double-logo {
    img:first-child {
      display: block;
    }
    img:last-child {
      display: none;
    }
  }

  .navbar-simple-ghost.navbar-stuck.navbar-craycode {
    background: #fff;

    .navbar-nav > .nav-item > .nav-link {
      color: #000;
    }

    .double-logo {
      img:first-child {
        display: none;
      }
      img:last-child {
        display: block;
      }
    }

    ul {
      li {
        a {
          color: red;
        }
      }
    }
  }

  .bg-gradient, .block-title::after, .animated-digits::after, .step .step-title::after, .btn-gradient, .btn-style-2.btn-gradient::after, .btn-style-2.btn-gradient:hover::before, .btn-style-6.btn-gradient::before, .accordion-style-3 .card-body::after, .accordion-style-3 [data-toggle="collapse"], .widget-title::after, .tag-link::before, .noUi-connect, .mobile-menu .dropdown-header::after, .blog-card .post-title::after {
    background: $primary !important;
  }

  .craycode-icon {
    width: 150px;
    height: 150px;
    line-height: 150px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    margin: auto;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    i {
      margin-top: 45px;
      font-size: 60px;
      color: $primary;
    }
  }

  .small-lh {
    line-height: 1.4em;
  }

  .anchor {
    margin-top: 0px;
    position: absolute;
  }

  .text-md-right.block-title {
    &:after {
      @include media-breakpoint-up(md) {
        left: auto;
        right: 0;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .team-card-style-4 {
      height: 100%;
      padding: 20px 10px 10px;

      h4 {
        font-size: 1rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .page-title .page-title-heading {
      font-size: 1.8rem;
    }
  }

  .about {
    overflow: hidden;
  }
}


.text-center .sb-style-2, .text-center .sb-style-3, .text-center .sb-style-4, .text-center .sb-style-5, .text-center .sb-style-6, .text-center .sb-style-7 {
  margin-right: 1rem;
}