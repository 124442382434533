// 
// user-variables.scss
// Use this to overwrite Bootstrap and CreateX variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary: #00a9de;
$footer-bg: $primary;
$headings-color: #222;

.bg-craycode {
  background: darken($footer-bg, 5%);
}
